import { createRouter, createWebHistory } from 'vue-router'

import page from '../views/page.vue'
import home from '../views/home.vue'

const routes = [
    {
        path: '/',
        name: 'page',
        component: page,
        children: [
            {
                path: '',
                name: 'home',
                component: home
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
