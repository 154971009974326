<template>
    <div class="collection">
        <div class="name">Yunqi Collection</div>
        <div class="writeup">
            Yunqi Cardz is the only access to compete with other holders to earn $YUN tokens.
        </div>
        <div class="writeup">
            Each player has unique opportunities upon game launch.
        </div>
        <div class="writeup">
            Hold any of the collection below? You might be Whitelisted for Yunqi Upcoming mint.
        </div>

        <div class="grid">
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/gaias.jpg"/>
                </div>
                <div class="nft_name">Onchain gaias</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/fellas.jpg"/>
                </div>
                <div class="nft_name">Based fellas</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/mochimons.jpg"/>
                </div>
                <div class="nft_name">Mochimons</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/primitive.jpg"/>
                </div>
                <div class="nft_name">Primitive</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/gods.jpg"/>
                </div>
                <div class="nft_name">Base Gods</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/nftoshi.jpg"/>
                </div>
                <div class="nft_name">Nftoshis</div>
            </div>
            <div class="grid_box">
                <div class="nft">
                    <img src="../../assets/frog.jpg"/>
                </div>
                <div class="nft_name">Tiny based Frogs</div>
            </div>
            <!-- <div class="grid_box content">
                <div class="collection_size">
                    Collection size: 10,000 Yunqi Cardz
                </div>
                <div class="collection_info">
                    Holders of these top 7 collection on Base (snapshot 06:00 UTC 1st April 2024)
                    Will be eligible to mint 5,000 Yunqi Cardz for free (FCFS)
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .collection {
        
        max-width: 1100px;
        width: 100%;

        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
        color: white;
        padding: 30px;
        box-sizing: border-box;
    }
    .name {
        font-size: 35px;
        font-weight: 800;
    }
    .writeup {
        margin-top: 5px;
        /* max-width: 80%; */
        font-size: 14px;
    }


    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 30px;
        margin-top: 30px;
    }

    .grid_box {
        /* padding: 10px; */
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border-bottom: 1px solid white;
    }
    .nft {
        /* aspect-ratio: 1/1; */
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .nft img {
        max-height: 100%;
        max-width: 100%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: block;
    }
    .nft_name {
        /* color: black; */
        padding: 15px;
        font-weight: 300;
        font-size: 18px;
    }

    .content {
        padding: 20px;
    }
    .collection_size {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .collection_info {
        font-size: 14px;
        line-height: 25px;
    }

    
    @media screen and (max-width: 870px) {
        .collection {
            padding: 20px;
            margin-top: 0px;
        }
    }
</style>