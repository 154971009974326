<template>
    <div class="page">
        <router-view></router-view>
    </div>
</template>

<script setup>
</script>

<style scoped>
    .page {
        height: 100vh;
        overflow: auto;
        background-image: url('../assets/bg.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>