<template>
    <div class="home">
        <headerlayout class="sticky"/>
        <banner @whitelist="scroll_down()"/>
        <!-- <div id="whitelist" ref="whitelist_ref">
            <whitelist/>
        </div> -->
        <collection/>
        <footerLayout/>
    </div>
</template>

<script setup>
import headerlayout from '@/components/header.vue';
import banner from '../components/home/banner.vue';
import collection from '../components/home/collection.vue'
// import whitelist from '@/components/home/whitelist.vue';

import footerLayout from '@/components/footer.vue'

import { ref } from 'vue';

const whitelist_ref = ref(null)

function scroll_down() {
    
    whitelist_ref.value.scrollIntoView({
        behavior: "smooth"
    })
}

</script>


<style scoped>
    .sticky {
        position: sticky;
        z-index: 1;
        top: 0px;
    }
</style>