<template>
    <div :style="{'--main': c.main, '--trans': c.trans, '--max_width': c.max_width}">
        <router-view/>
    </div>
</template>

<script setup>
    import { ref } from "vue"

    document.title = 'Yunqi Cardz'

    const c = ref({
        'main': 'rgb(48, 79, 254)',
        'trans': 'rgba(48, 79, 254, 0.2)',
        'max_width': '1400px'
    })
</script>

<style>

@import 'vue3-toastify/dist/index.css';
@import 'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap';


#app {
    font-family: "Dosis", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    padding: 0px;
    margin: 0px;
    letter-spacing: 1px;
}

a {
    color: inherit;
    text-decoration: none;
}

</style>
