import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)

import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost/yunqi/index.php/'
    baseURL: 'https://yunqicardz.com/server/index.php/'
});

app.use(VueAxios, axios_instance)
app.provide('axios', app.config.globalProperties.axios)



// import { Toaster } from "@meforma/vue-toaster";
// app.use(Toaster, {
    
//     position: "bottom",
//     duration: 2000
// }).provide('toast', app.config.globalProperties.$toast);



app.mount('#app')
